.App {
  text-align: left;
}

.container{
  margin-left: 20px;
}

body {
  background: linear-gradient(to right, #fff, #89a292, #3f7d5f, #146132, #007c34);
  background-size: 500% 100%; 
  animation: gradientShift 20s infinite linear alternate;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}

footer {
  position: fixed;
  background: linear-gradient(to right, rgb(81, 54, 7), rgb(120, 120, 120));
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  text-align: center;
}

.header {
  position: fixed;
  background: linear-gradient(to right, rgb(120, 120, 120), rgb(81, 54, 7));
  top: 0;
  left: 0;
  right: 0;
  padding: 15px;
  text-align: center;
}